<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('comment.comments') }}</h2>
    </div>
    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterComments" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterComments">
          {{ $t('general.search') }}
        </el-button>
        <!-- <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button> -->
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" :label="$t('comment.comment')" min-width="300px">
          <template slot-scope="scope">
            <span>{{ scope.row.comment }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('general.author')" min-width="180px">
          <template slot-scope="scope">
            <div class="full-width">
              <div class="half-photo">
                <div class="demo-basic--circle">
                  <div>
                    <el-avatar shape="square" :size="50" :src="scope.row.author.avatar" />
                  </div>
                </div>
              </div>
              <div class="half-right">
                <div class="in-td">
                  <h4>{{ scope.row.author.name | uppercaseFirst }}</h4>
                  <small>{{ scope.row.author.email }}</small>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('comment.postTitle')" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.article==null?'':scope.row.article.title??'' }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['edit comment','hide comment','delete comment'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="150">
          <template slot-scope="scope">
            <!-- <el-button v-if="scope.row.is_visible && checkPermission(['edit comment'])" type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="handleEditComment(scope.row.id);" /> -->
            <el-button v-if="checkPermission(['hide comment','delete comment'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteComment(scope.row.id);" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getCommentsList" />
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const commentResource = new Resource('comments');
export default {
  name: 'CommentsList',
  components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      editing: false,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      },
      currentComment: {},
    };
  },
  computed: {
  },
  created() {
    this.getCommentsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCommentCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelCommentForm() {
      this.$refs.CommentCreationDrawer.closeDrawer();
    },
    getprocessingColor(processing){
      var color = 'primary';
      if (processing === 'waiting'){
        color = 'warning';
      } else if (processing === 'finished'){
        color = 'success';
      } else if (processing === 'canceled'){
        color = 'info';
      }
      return color;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      };
      this.handleFilterComments();
    },
    async getCommentsList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await commentResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getSubCommentsList(subUrl) {
      this.loading = true;
      const { data } = await commentResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterComments() {
      this.query.page = 1;
      this.getCommentsList();
    },

    handleDeleteComment(id) {
      this.$swal({
        title: this.$t('general.SureQuestion'),
        text: this.$t('general.irrversibleMessage'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('general.ouiSupprimer'),
      }).then((result) => {
        if (result.isConfirmed) {
          commentResource.destroy(id).then(response => {
            this.$message({
              type: 'success',
              message: this.$t('comment.suppressionEffectue'),
            });
            this.initSearch();
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },

    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('comment.comment')];
        const filterVal = ['id', 'comment'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Comments list GIFTOLIFE_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
